
import Main from "../Main/Frontend";
import Header from "../header/Header";

const FrontendPage = () => {
    return (

        <div className="App">
            <Header />
            <Main />
        </div>
    )
}

export default FrontendPage;